@import 'global';

.Banner
{
    height: 400px;
    position: relative;
    direction: row;

    .Media
    {
        background-color: white;
        height: 100%;
        overflow: hidden;

        position: relative;

        .MediaCaption
        {
            text-overflow: ellipsis;

            position: absolute;
            bottom: 0;

            padding: 15px;

            background-color: black;
            color: white;
            opacity: 0.6;

            width: 100%;
            height: 20%;

            font:
            {
                size: $header-font-size;
                weight: 200;
            }

            transition: 300ms;
            cursor: pointer;
            &:hover
            {
                opacity: 0.8;
            }

        }

        transition: 300ms;
        cursor: pointer;
        &:hover
        {
            filter: brightness(115%);
        }
    }

    .BannerGrid
    {
        height: 100%;
    }

    .Content
    {
        color: white;
        background-color: $dark-red;
        height: 100%;

        cursor: pointer;

        padding: 30px;

        transition: 300ms;

        &:hover, &:active
        {
            background-color: $dark-red-active;

            .ViewButton
            {
                background-color: $almost-white;
                color: $dark-red
            }
        }

        .Title
        {
            font-size: $huge-font-size;
            font-weight: 500;
        }

        .Caption
        {
            margin-top: 10px;
            font-size: $bigger-font-size;
        }

        .ViewButton
        {
            margin-top: 40px;
            color: white;

            font-size: $header-font-size;
            border: 3px solid white;
            text-transform: capitalize;

            transition: 200ms;
        }
    }
}
